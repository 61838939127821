/* Container styles */
.social-media-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Adjust the gap as needed */
  }
  
  /* Button styles */
  .social-media-button {
    width: 100%; /* Make all buttons the same width */
    max-width: 250px; /* You can adjust the max width as needed */
  }
  
  /* Optional: Add some hover effect */
  .social-media-button:hover {
    transform: scale(1.05);
    transition: transform 0.2s;
  }


  
  